<template>
  <div class="resource-detail">
    <div class="rd-button-contact display-flex-center" @click="openZoosUrl">
      <img style="margin-top: 3px" width="16" height="16"
           src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/public/202203/lyg_chat_iconAS.png"/>
      <span style="margin-left: 10px">联系专利</span>
    </div>
    <div class="rd-title ellipse-1 ">
      {{ detaiItem.ti }}
    </div>
    <div class="rd-tag display-flex-center">
      <div class="rd-tagA">{{ detaiItem.sectionName }}</div>
      <div class="rd-tagB">{{ detaiItem.lastLegalStatus }}</div>
    </div>
    <Row :gutter="10">
      <Col span="12" class="ellipse-1 rd-des">专利类型：{{ detaiItem.sectionName }}
      </Col>
      <Col span="12" class="ellipse-1 rd-des">申请号：{{ detaiItem.an }}</Col>
    </Row>
    <Row :gutter="10">
      <Col span="12" class="ellipse-1 rd-des">专利保护年限：20年
      </Col>
      <Col span="12" class="ellipse-1 rd-des">申请日期：{{ detaiItem.ad }}</Col>
    </Row>
    <Row :gutter="10">
      <Col span="12" class="ellipse-1 rd-des">发明设计人：{{ detaiItem.pin }}
      </Col>
      <Col span="12" class="ellipse-1 rd-des">申请人：{{ detaiItem.pa }}</Col>
    </Row>
    <Row :gutter="10">
      <Col span="12" class="ellipse-1 rd-des">主分类号：{{ detaiItem.pic }}
      </Col>
      <Col span="12" class="ellipse-1 rd-des">申请人地址：{{ detaiItem.ar }}</Col>
    </Row>
    <Row :gutter="10">
      <Col span="12" class="ellipse-1 rd-des">授权公众号：{{ detaiItem.pnm }}
      </Col>
      <Col span="12" class="ellipse-1 rd-des">代理人：{{ detaiItem.agt }}</Col>
    </Row>
    <Row :gutter="10">
      <Col span="12" class="ellipse-1 rd-des">公告日：{{ detaiItem.pd }}
      </Col>
      <Col span="12" class="ellipse-1 rd-des">代理机构：{{ detaiItem.agc }}</Col>
    </Row>
    <div class="rd-blank">摘要</div>
    <div class="rd-content">
      {{ detaiItem.ab }}
    </div>
    <div class="rd-blank">权利要求</div>
    <div class="rd-content" v-html="detaiItem.clm"/>
    <div class="rd-blank" v-if="detaiItem.abPicPath">专利图片</div>
    <div class="rd-img" v-if="detaiItem.abPicPath">
      <img :src="detaiItem.abPicPath"/>
    </div>
  </div>
</template>

<script>
import {getPatentDetail} from "../../plugins/api/resourceApi";

export default {
  name: "ResourceDetail",
  data() {
    return {
      detaiItem: {
        ab: '摘要',
        abPicPath: '专利图片',
        ad: '申请日',
        agc: '代理机构',
        agt: '代理人',
        an: '申请号',
        ar: '申请人地址',
        clm: '权利要求',
        lastLegalStatus: '法律状态',
        pa: '申请人',
        pd: '公告日',
        pic: '主分类号',
        pin: '发明设计人',
        pnm: '授权公告号',
        sectionName: '专利类型',
        ti: '专利名称'
      },
    }
  },
  mounted() {
    let an = this.$route.query.an
    getPatentDetail({"an": an}).then((res) => {
      if (res.code == 0) {
        this.detaiItem = res.result;
      }
    })

  },
  methods: {
    openZoosUrl() {
      openZoosUrl('chatwin');
    }
  }
}
</script>

<style scoped>

.resource-detail {
  width: 1200px;
  margin: 30px auto 50px auto;
  background: linear-gradient(180deg, rgba(42, 139, 239, 0.29), rgba(255, 255, 255, 0) 243px, rgba(255, 255, 255, 0));
  border-radius: 6px;
  box-shadow: 1px 1px 10px rgba(229, 239, 249, 1);
  padding: 32px 50px;
  position: relative;
}

.rd-title {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  text-align: center;
}

.rd-tag {
  display: flex;
  flex-direction: row;
  margin-top: 28px;
}

.rd-tagA {
  background: #FFDBCD;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #FE7930;
  padding: 3px 14px;
}

.rd-tagB {
  background: #CADFFE;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #0F62DF;
  padding: 3px 14px;
  margin-left: 10px;
}

.rd-des {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}

.rd-blank {
  width: 120px;
  height: 30px;
  background: linear-gradient(90deg, #0F62DF 0%, #FFFFFF 100%);
  font-size: 18px;
  font-weight: bold;
  color: #FFFFFF;
  margin-top: 45px;
  padding-left: 10px;
  display: flex;
  align-items: center;
}

.rd-content {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 36px;
  margin-top: 29px;
}

.rd-img {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.rd-button-contact {
  width: 112px;
  height: 32px;
  background-color: #0F62DF;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

</style>

